<template>
  <LView class="mt-10 text-xl font-normal text-gray-600">
    <template #body>
      <div class="max-w-[85rem] px-4 pt-8 mx-auto">
        <AccordionApp
          v-for="(card, index) in cards"
          :key="index"
          :cardNumber="index + 1"
          :title="card.title"
          :subTitles="card.subTitles"
          :titleColor="card.titleColor"
          :contentColor="card.contentColor"
          :numberColor="card.numberColor"
          :cardTitle="card.cardTitle"
        />
      </div>
    </template>
  </LView>
</template>

<script setup>
import AccordionApp from "@/views/Education&training/AccordionApp.vue";
import LView from "@/components/layout/LView.vue";
import useCard from "@/composables/useCard.js";

const { cards } = useCard();
</script>
